import { Fragment } from "react"
import { Footer, Header } from "../../components"
import About from "./About"
import Banner from "./Banner"
import Services from "./Services"
import Statistics from "./Statistics"
import Testimonial from "./Testimonial"
import WeOffers from "./WeOffers"

function Home() {
    return (
        <Fragment>
            <Header/>
            <Banner/>
            <Services/>
            <About/>
            <WeOffers/>
            <Statistics/>
            <Testimonial/>
            <Footer/>
        </Fragment>
    )
}

export default Home