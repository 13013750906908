import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './styles/app.scss'
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";


function App() {
  useEffect(() => {
    Aos.init({
      disable: 'mobile'
    });
  }, [])
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App