import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FadeUp, Section } from "../../components";

function Services() {
    return (
        <Section id='services'>
            <Container>
                <FadeUp>
                    <h3 className="text-center fw-bold">We Provide the Best Services</h3>
                </FadeUp>
                <Row className="align-items-center">
                    <Col md={6} lg={3}>
                        <div className="service-card shadow" data-aos="flip-left" data-aos-easing="ease" data-aos-delay={400}>
                            <div className="img-circle service-icon shadow">
                                <LazyLoadImage effect="blur" src="https://cdn-icons-png.flaticon.com/512/4514/4514034.png"/>
                            </div>
                            <p className="text-white m-0 py-3 fw-semibold lead">Executive Hiring</p>
                        </div>
                    </Col>
                    <Col md={6} lg={3}>
                        <div className="service-card shadow" data-aos="flip-left" data-aos-easing="ease" data-aos-delay={800}>
                            <div className="img-circle service-icon shadow">
                                <LazyLoadImage effect="blur" src="https://cdn-icons-png.flaticon.com/512/3188/3188168.png"/>
                            </div>
                            <p className="text-white m-0 py-3 fw-semibold lead">Contract Staffing</p>
                        </div>
                    </Col>
                    <Col md={6} lg={3}>
                        <div className="service-card shadow" data-aos="flip-left" data-aos-easing="ease" data-aos-delay={1200}>
                            <div className="img-circle service-icon shadow">
                                <LazyLoadImage effect="blur" src="https://cdn-icons-png.flaticon.com/128/3853/3853563.png"/>
                            </div>
                            <p className="text-white m-0 py-3 fw-semibold lead">Talent Acquisition</p>
                        </div>
                    </Col>
                    <Col md={6} lg={3}>
                        <div className="service-card shadow" data-aos="flip-left" data-aos-easing="ease" data-aos-delay={1600}>
                            <div className="img-circle service-icon shadow">
                                <LazyLoadImage effect="blur" src="https://cdn-icons-png.flaticon.com/128/942/942833.png" />
                            </div>
                            <p className="text-white m-0 py-3 fw-semibold lead">Permanent Hiring</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default Services