import { Col, Container, Row, Card } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FadeUp, Section } from "../../components";

function WeOffers() {
    return (
        <Container>
            <FadeUp>
                <h3 className="text-center fw-bold mb-5">Industries We Serve</h3>
            </FadeUp>
            <Row className="g-5">
                <Col md={4}>
                    <Card className="border-0 shadow rounded-4" data-aos="flip-left" data-aos-easing="ease" data-aos-delay={400}>
                        <Card.Body className="text-center">
                            <LazyLoadImage width="80px" className="my-3" src="https://cdn-icons-png.flaticon.com/128/10098/10098186.png" />
                        </Card.Body>
                        <Card.Footer className="border-0 bg-dark rounded-4">
                            <p className="m-0 py-2 text-center fs-4 text-white lead fw-bold">IT Sector</p>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="border-0 shadow rounded-4" data-aos="flip-left" data-aos-easing="ease" data-aos-delay={800}>
                        <Card.Body className="text-center">
                            <LazyLoadImage width="80px" className="my-3" src="https://cdn-icons-png.flaticon.com/512/2920/2920281.png" />
                        </Card.Body>
                        <Card.Footer className="border-0 bg-dark rounded-4">
                            <p className="m-0 py-2 text-center fs-4 text-white lead fw-bold">Non IT Sector</p>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="border-0 shadow rounded-4" data-aos="flip-left" data-aos-easing="ease" data-aos-delay={1200}>
                        <Card.Body className="text-center">
                            <LazyLoadImage width="80px" className="my-3" src="https://cdn-icons-png.flaticon.com/128/925/925025.png" />
                        </Card.Body>
                        <Card.Footer className="border-0 bg-dark rounded-4">
                            <p className="m-0 py-2 text-center fs-4 text-white lead fw-bold">BPO Sector</p>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default WeOffers