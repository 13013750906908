import { LazyLoadImage } from 'react-lazy-load-image-component'
import { IoCall, IoLogoFacebook, IoLogoInstagram, IoLogoWhatsapp, IoMail } from 'react-icons/io5';

function Footer() {
    return (
        <footer className="bg-dark py-4" id='contact'>
            <div className="container">
                <div className="row">
                    <div className="col-12 py-3 mb-4">
                        <LazyLoadImage effect="blur" width='100px' className='p-1 bg-white' src={require('../images/logo.png')} />
                    </div>
                    <div className="col-xl-3">
                        <address className='mb-4'>
                            <h5>ADDRESS</h5>
                            <p className='footer-text'>No. 47 Harris Road,
                                Sai Nagar Annex Rd, Nehru Nagar,
                                Virugambakkam, Chennai, Tamil Nadu-600092</p>
                        </address>
                    </div>
                    <div className="col-xl-3">
                        <div className='mb-4'>
                            <h5>CONTACT US</h5>
                            <p className='footer-text'><IoCall size={20} className='me-2' />+91 9345149704</p>
                            <p className='footer-text'><IoMail size={20} className='me-2' />ctwhrsolution@gmail.com</p>
                        </div>
                        <div className='mb-4'>
                            <h5>CONNECT WITH US</h5>
                            <a href="" className='footer-text me-2'><IoLogoWhatsapp size={25} /></a>
                            <a href="" className='footer-text me-2'><IoLogoInstagram size={25} /></a>
                            <a href="" className='footer-text me-2'><IoLogoFacebook size={25} /></a>
                        </div>
                    </div>
                    <div className="col-xl-3">
                        <div className='mb-4'>
                            <h5>QUICK LINKS</h5>
                            <div><a href="" className='footer-text'>About Us</a></div>
                            <div><a href="" className='footer-text'>Services</a></div>
                            <div><a href="" className='footer-text'>Careers</a></div>
                            <div><a href="" className='footer-text'>Contact Us</a></div>
                        </div>
                    </div>
                    <div className="col-xl-3">
                        <div className='mb-4'>
                            <h5>SERVICES</h5>
                            <div><a href="" className='footer-text'>Executive Hiring</a></div>
                            <div><a href="" className='footer-text'>Contract Staffing</a></div>
                            <div><a href="" className='footer-text'>Talent Acquisition</a></div>
                            <div><a href="" className='footer-text'>Permanent Hiring</a></div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <form action="mailto:faridabanu02@gmail.com" method="post" enctype="text/plain" className='pt-4 col-md-6 mx-auto'>
                            <h5>Get In Touch</h5>
                            <div className="my-3">
                                <small className='d-block pb-1'>Name</small>
                                <input type="text" name='name' className='form-control bg-dark text-white' required />
                            </div>
                            <div className="mb-3">
                                <small className='d-block pb-1'>Phone</small>
                                <input type="number" name='mobile' className='form-control bg-dark text-white' required />
                            </div>
                            <div className="mb-3">
                                <small className='d-block pb-1'>Email</small>
                                <input type="email" name='mail' className='form-control bg-dark text-white' required />
                            </div>
                            <div className="mb-3">
                                <small className='d-block pb-1'>Message</small>
                                <textarea name='comment' className='form-control bg-dark text-white'></textarea>
                            </div>
                            <div className="text-center">
                                <button type="submit" className='mx-auto btn btn-light rounded-pill'>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer