import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FadeUp } from '../../components';


function Banner() {
    return (
        <Container className='banner'>
            <Row className='align-items-center '>
                <Col md={6} className="py-2">
                    <FadeUp delay={200}>
                        <h1 className='display-6 fw-semibold'>We Create</h1>
                    </FadeUp>
                    <FadeUp delay={400}>
                        <h1 className='display-6 fw-semibold'>Solution for</h1>
                    </FadeUp>
                    <FadeUp delay={600}>
                        <h1 className='fw-bold display-4'>Your Business</h1>
                    </FadeUp>
                    <FadeUp delay={800}>
                        <p className='lead m-0 fw-normal fs-4'>Develope a plan for implementing the</p>
                    </FadeUp>
                    <FadeUp delay={1000}>
                        <p className='lead m-0 fw-normal fs-4'>Solution. This should include specific</p>
                    </FadeUp>
                    <FadeUp delay={1200}>
                        <p className='lead m-0 fw-normal fs-4'>action, timelines, and responsibilities.</p>
                    </FadeUp>
                    <FadeUp delay={1400}>
                        <a href="#contact" className='btn btn-action btn-dark'>Know More</a>
                    </FadeUp>
                </Col>
                <Col md={6}>
                    <LazyLoadImage effect="blur" src={require('../../images/banner.png')} width={'100%'} />
                </Col>
            </Row>
        </Container>
    )
}

export default Banner