import { LazyLoadImage } from "react-lazy-load-image-component"
import { Section } from "../../components"
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';


function Testimonial() {
    return (
        <Section>
            <div className="col-lg-6 mx-auto">
                <h3 className="text-center fw-bold mb-5">Testimonials</h3>
                <div className="mb-4 card border-0 shadow-lg text-center rounded-4">
                    <div className="card-body p-4">
                        <LazyLoadImage width={100} effect="blur" src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png" />
                        <div className="my-2">
                            <FaStar color="orange" size={28} />
                            <FaStar color="orange" size={28} />
                            <FaStar color="orange" size={28} />
                            <FaStar color="orange" size={28} />
                            <FaStarHalfAlt color="orange" size={28} />
                        </div>
                        <p className="text-center mt-3 fw-bold">Mr. Charles Sobhraj</p>
                        <p className="m-0">"They keep their promises to the candidates, they periodically send me to interviews. Got placed in most of the lucrative companies in India"</p>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default Testimonial