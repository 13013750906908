import { Container, Row, Col } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { FadeUp, Section } from '../../components'
function About() {
    return (
        <Section id='about-us'>
            <Container>
                <Row>
                    <Col md={6}>
                        <LazyLoadImage effect="blur" src={require('../../images/banner-2.png')} width={'100%'} />
                    </Col>
                    <Col md={6}>
                        <FadeUp delay={200}>
                            <h3>Why to Choose Us</h3>
                        </FadeUp>
                        <FadeUp delay={400}>
                            <p className='lead m-0 fw-normal fs-4'>Our Methodology towards HR Consulting goes with a deep understanding of our client's business and their people. We also relate the HR practices prevalent in the market and recommend the best possible fit for the client.</p>
                        </FadeUp>
                        <FadeUp delay={600}>
                            <a href="#contact" className='btn btn-action btn-dark'>Know More</a>
                        </FadeUp>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default About