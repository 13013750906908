import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Header() {
    return (
        <Navbar bg="white" expand="xl">
            <Container>
                <Navbar.Brand href="#home">
                    <LazyLoadImage effect="blur" width='100px' src={require('../images/logo.png')}/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className='shadow-sm border-0' />
                <Navbar.Collapse id="basic-navbar-nav" >
                    <Nav className="mx-auto">
                        <Nav.Link href="#home"> Home</Nav.Link>
                        <Nav.Link href="#about-us">about us</Nav.Link>
                        <Nav.Link href="#services">services</Nav.Link>
                        <Nav.Link href="#careers">careers</Nav.Link>
                        <Nav.Link href="#contact">contact</Nav.Link>
                    </Nav>
                    <Nav>
                        <a href="" className='btn btn-dark btn-nav'>SUBMIT RESUME</a>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header