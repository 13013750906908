import { Container, Row, Col} from "react-bootstrap"
import { Section } from "../../components"
import CountUp from 'react-countup';

function Statistics() {
 
    return (
        <Section className="bg-dark mt-5 text-center">
            <Container onScroll={(e) => console.log(e,'s')}>
                <h2 className="text-white mb-5">A Unique Approach To Staffing</h2>
                <Row>
                    <Col md={6} lg={3} className="py-3">
                        <h2 className="text-white fw-bold"><CountUp className="text-white" end={5} enableScrollSpy={true} />+</h2>
                        <h3 className="text-white">Experience</h3>
                    </Col>
                    <Col md={6} lg={3} className="py-3">
                        <h2 className="text-white fw-bold"><CountUp className="text-white" end={800} enableScrollSpy={true} />+</h2>
                        <h3 className="text-white">Openings</h3>
                    </Col>
                    <Col md={6} lg={3} className="py-3">
                        <h2 className="text-white fw-bold"><CountUp className="text-white" end={50} enableScrollSpy={true} />+</h2>
                        <h3 className="text-white">Clients</h3>
                    </Col>
                    <Col md={6} lg={3} className="py-3">
                        <h2 className="text-white fw-bold"><CountUp className="text-white" end={100} enableScrollSpy={true} />%</h2>
                        <h3 className="text-white">Satisfied Clients</h3>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default Statistics